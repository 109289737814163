







































































































.release-video {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
    color: #fff;
    .header {
        margin: 0 0 30px;
        font-size: 16px;
    }
    .main {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .content {
            display: flex;
            flex-direction: column;
            height: 100%;
            /deep/ .label {
                margin: 20px 0;
            }
            .video-content {
                display: flex;
                margin-bottom: 20px;
                margin-top: 0;
                .icon {
                    margin-left: 5px;
                    color: #FF3E6C;
                }
            }
        }
        
    }
    .operation {
        text-align: center;
        .cancel {
            margin-right: 10px;
            color: #fff;
            background-color: #0C0E3F;
            border-color: #262963;
        }
    }
}
/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}
