



















































.video-upload {
    box-sizing: border-box;
    flex: 1;
    .file-input {
        display: none;
    }
    .upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 660px;
        height: 360px;
        margin-bottom: 20px;
        background-color: #0c0e3f;
        border-radius: 4px;
        cursor: pointer;
        .icon {
            font-size: 64px;
        }
        .label {
            margin: 10px 0 15px;
            font-size: 18px;
        }
    }
    .hint {
        display: flex;
        color: #5F5E80;
    }
}
